:root{
    --miniatura-year-text-color: var(--secondary-Grayblue-500);
    --miniatura-title-text-color: var(--secondary-Grayblue-500);
    --miniatura-client-text-color: var(--secondary-Grayblue-500);
}

.miniatura {
    box-sizing: border-box;
    height: 18vw;
    overflow: visible;
    width: calc((100% - 48px) / 4);
}
.miniatura-main {
    align-items: end;
    background-color: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    gap: 16px;
    height: 100%;
    flex-direction: column;
    flex-shrink: 0;
    padding: 8px;
    text-align: center;
    transition: all ease 0.3s;
    width: 100%;
}

.miniatura-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    height: calc(70% - 16px);
    width: 100%;
}

.miniatura-desc {
    align-items: flex-start;
    /* background-color: rgba(44,47,72,1); */
    box-sizing: border-box;
    cursor: pointer;
    gap: 4px;
    display: flex;
    flex-direction: column;
    min-height: 30%;
    justify-content: flex-start;
    padding: 20px;
    text-align: left;
    transition: background-color 0.4s linear;
    width: 100%;
}

.miniatura-main:hover {
    margin-top:-10px;
    box-shadow: 0 10px 9px rgba(200, 200, 200, 1);
}

.seccion_descripcion{
    color: var(--miniatura-client-text-color);
    display: block;
}
.miniatura h3 {
    color: var(--miniatura-title-text-color);
    text-overflow: ellipsis;
}

.miniatura h6 {
    color: var(--miniatura-year-text-color);
}

@media only screen and (min-width: 1133px) and (max-width: 1366px){
    .miniatura {
        height: 30vw;
    }
}

@media only screen and (min-width: 744px) and (max-width: 1133px){
    .miniatura {
        height: 30vw;
    }
}
@media (max-width: 700px){
    header {
        height: 10vh;
        padding: 0 16px;
        position: fixed;
        /* margin: auto auto 0 auto; */
    }
    footer {
        padding: 40px 20vw 15vh 20px;
    }
    .central {
        gap: 24px;
        padding: 0 16px 24px 16px;
        width: 100vw;
    }
    .miniatura {
        width: calc((100% - 16px) / 2);
        height: 30vh;
    }

    .miniatura-main {
        border-radius: 4px;
    }

    .miniatura-img {
        height: calc(70% - 16px);
    }
    
    .miniatura-desc {
        height: 30%;
        overflow: hidden;
        padding: 0;
    }
    .miniatura h3 {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
    }
    
}