:root {
    --switch-border-radius: 8px;
    --switch-background-color: var(--primary-white);
}
.langSwitcher{
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 10px;
    position: absolute;
    right: 0;
    top:0;
    z-index: 4;
}
.langSwitcher_label{
    font-weight: bold;
    font-size: 12px;
}
.langSwitcher_sp:hover , .langSwitcher_en:hover{
    cursor: pointer;
    overflow: hidden;
}
@media only screen and (min-width: 744px) and (max-width: 1180px){
    .langSwitcher{
        background: var(--switch-background-color);
        border-radius: var(--switch-border-radius) 0 0 var(--switch-border-radius);
        gap: 8px;
        flex-direction: column;
        position: absolute;
        margin-right: 0;
        margin-top: 10vh;
        z-index: 2;
    }
    .langSwitcher_sp:hover img , .langSwitcher_en:hover img{
        height: 40px;
    }
}