@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('colors.css');
@import url('efects.css');
@import url('textos.css');
@import url('formElements.css');
@import url('scrollbar.css');

main {
    box-sizing: border-box;
    height: 100%;
    margin: 0;
    padding: 0 0 0 0;
    width: 100%;
}

ul {
    margin: 0;
    padding: 0;
}
li{
    margin: 0;
    padding: 0;
    list-style: none;
}
body {
    align-items: center;
    background: rgb(234, 235, 237);
    /* background: rgba(44,47,72,1); */
    /* background: radial-gradient(circle, rgba(97,34,83,1) 0%, rgba(44,47,72,1) 75%, rgba(44,47,72,1) 100%); */
    /* background: radial-gradient(circle, rgbargba(44,47,72,1) 0%, rgba(44,47,72,1) 75%, rgba(44,47,72,1) 100%); */
    background-position-y: -834px;
    box-sizing: border-box;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    padding: 0 40px;
    position: relative;
    transition: all;
    width: 100%;
}

.shadow {
    box-shadow: #00000063 0 0 10px;
}

#galeria {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.m_on {
    display: block;
    opacity: 1;
    position:fixed;
}

@starting-style {
    .m_on {
      opacity: 0;
    }
  }

.m_off {
    display: none;
}
.contenedor_info {
    color: var(--modal-text-info-color);
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
}

#mainT{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 16px; */
    align-items: center;
}
.seccion_descripcion{
    display: block;
}
.contenedor_text p {
    line-height: 25px;
}

@media only screen and (min-width: 744px) and (max-width: 1133px){
    main {
        padding: 0 0 0 0;
    }
    .central {
        width: 90VW;
    }
    .biografia {
        margin-bottom: 40;
    }
    .central {
        gap: 0px;
    }
    .biografia_nombre {
        width: 100%;
    }
    .biografia_sobremi {
        width: 100%;
    }
    .contenedor {
        height: 80vh;
        width: 90vw;
    }
    .contenedor_text p {
        font-size: 0.9rem;
    }
    .img {
        width: 70vw;
    }
}
@media (max-width: 700px){
    main {
        padding: 0;
    }
    body {
        padding: 0;
    }
    .central {
        gap: 24px;
        padding: 0 16px 24px 16px;
        width: 100vw;
    } 
    
}