.contactoContenedor {
    /* background-color: var(--contacto-section_background-color); */
    border-radius: 8px;
    box-shadow: 0 3px 5px rgba(180,180,180, 0.7);
    box-sizing: border-box;
    column-gap: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 0vh auto 8vh auto;
    overflow: hidden;
    width: 100%;
}

.contactoContext {
    background-color: var(--contacto-context_background-color);
    border-radius: 4px 0 0 4px;
    color: var( --contacto-context_text-color);
    display: flex;
    gap: 24px;
    flex-direction: column;
    padding: 68px 34px 34px 34px;

}

.constactoForm {
    background-color: var(--contacto-form_background-color);
    display: grid;
    grid-gap: 24px;
    grid-template-columns: repeat(6, 1fr);
    padding: 68px 34px 34px 34px;

}

.contactoContext h2, .contactoContext p {
    height: fit-content;
    grid-column: 1 / 7;
    grid-row: auto;
}

input {
    width: 75%;
}

button {
    max-width: 300px;
    align-self: flex-end;
}

@media (max-width: 700px){
    .contactoContenedor {
        grid-template-columns: repeat(1, 1fr);
        margin: 0 0;
    }
    .central {
        padding: 0;
    }
    .contactoContext,.constactoForm {
        border-radius: 0;
        padding: 68px 32px 68px 32px;
    }
    input, button {
        box-sizing: border-box;
        max-width: 100%;
        width: 100%;
    }
}