body::-webkit-scrollbar, div::-webkit-scrollbar {
    -webkit-appearance: none;
    position: fixed;
    width: 10px;
    z-index: 1;
}
body::-webkit-scrollbar:vertical, div::-webkit-scrollbar:vertical {
    width: 10px;
}
body::-webkit-scrollbar:horizontal, div::-webkit-scrollbar:horizontal {
    height: 10px;
}
body::-webkit-scrollbar-track-piece, div::-webkit-scrollbar-track-piece {
    background: none;
    backdrop-filter: blur(2px);
}
body::-webkit-scrollbar-thumb , div::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}
body::-webkit-scrollbar-track , div::-webkit-scrollbar-track {
    border-radius: 10px; 
}
@media (max-width: 700px){
    body::-webkit-scrollbar, div::-webkit-scrollbar {
        -webkit-appearance: inherit;
    }
    body::-webkit-scrollbar:vertical, div::-webkit-scrollbar:vertical {
        width: inherit;
    }
    body::-webkit-scrollbar:horizontal, div::-webkit-scrollbar:horizontal {
        height:fit-content;
    }
    body::-webkit-scrollbar-track-piece, div::-webkit-scrollbar-track-piece {
        background: inherit;
    }
    body::-webkit-scrollbar-thumb , div::-webkit-scrollbar-thumb {
        background-color: inherit;
        border-radius: inherit;
        border: none;
    }
    body::-webkit-scrollbar-track , div::-webkit-scrollbar-track {
        border-radius: inherit; 
    }
    
}