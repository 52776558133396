:root{
    --seccion-title-text-color: var(--secondary-Grayblue-500);
}

.main {
    gap: 20px;
    margin: 20px auto 40px auto;
    position: relative;
    width: 100%;
}

.central {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 40px;
    min-height: 70vh;
    padding-bottom: 24px;
    padding-top: 80px;
    width: 60vw;
}
.seccion_title {
    color: var(--seccion-title-text-color);
}

.demo {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
}
@media only screen and (min-width: 1133px) and (max-width: 1366px){
    .central {
        width: 90vw;
    }
}

@media only screen and (min-width: 744px) and (max-width: 1133px){
    .central {
        width: 90vw;
    }
}
@media (max-width: 700px){
    .demo {
        gap: 8px;
        padding: 0 16px;
        width: 100%;
    }
}