:root {
    --primary-blue:#322E82;
    --primary-violet:#6B255B;
    --primary-red:#B41C2D;
    --primary-dark-gray:#333333;
    --primary-white:#ffffff;

    /* Blue */
    --secondary-blue-700: rgb(36, 33, 92);
    --secondary-blue-800: rgb(28, 25, 72);
    --secondary-blue-900: rgb(21, 19, 55);

    /* Violet */
    --secondary-violet-100:rgb(209, 187, 204);
    --secondary-violet-200:rgb(187, 155, 180);
    --secondary-violet-300:rgb(156, 109, 145);
    --secondary-violet-500:rgb(107, 37, 91);
    --secondary-violet-700:rgb(76, 26, 65);
    --secondary-violet-800:rgb(59, 20, 50);

    /* Red */
    --secondary-red-400:rgb(195, 73, 87);
    --secondary-red-500:rgb(180, 28, 45);

    /* Grayblue */
    --secondary-Grayblue-50: rgb(235, 234, 243);
    --secondary-Grayblue-100: rgb(191, 192, 200);
    --secondary-Grayblue-200: rgb(161, 159, 198);
    --secondary-Grayblue-300: rgb(116, 119, 137);
    --secondary-Grayblue-500: rgb(48, 52, 79);
    --secondary-Grayblue-600: rgb(44, 47, 72);
    --secondary-Grayblue-900: rgb(20, 22, 33);

    /*Gray scale*/
    --grayScale-gray-50: rgb(235, 235, 235);
    --grayScale-gray-200: rgb(161, 161, 161);

    /*Green*/
    --primaryGreen-300: rgb(133, 171, 115);
    --primaryGreen-700: rgb(52, 92, 33);


    /* 'Tokens' */

    /*Fomr elements*/
        /*label*/
        --form-label-color : var(--secondary-Grayblue-500);
        /*input placeholder*/
        --input-plaholder-color : var(--grayScale-gray-200);
        /*mensauje de error*/
        --input-errorMesage-color: var(--secondary-red-400);
        /*input normal*/
        --input-background-color : var(--primary-white);
        --input-placeholder-color : var(--grayScale-gray-200);

        /*button normal*/
        --button-background-color : var(--secondary-Grayblue-500);
        --button-text-color : var(--primary-white);

        /*button hover*/
        --button-backgroundHover-color : var(--secondary-Grayblue-300);
        --button-textHover-color : var(--secondary-Grayblue-500);

        /*button disabled*/
        --button-backgroundDisabled-color : var(--secondary-violet-200);
        --button-textdisabled-color : var(--grayScale-gray-200);

    /*Page contacto*/
        --contacto-section_background-color : var(--primary-white);
        --contacto-context_background-color : var(--secondary-Grayblue-500);
        --contacto-context_text-color: var(--primary-white);
        --contacto-form_background-color: var(--grayScale-gray-50);

    /* modal*/ 
        --modal-background-color : var(--grayScale-gray-50);
        --modal-text-info-color:var(--grayScale-gray-200);
        --modal-backButon-color:var(--primary-white);
        --modal-backButon-background-normal:var( --secondary-Grayblue-500);
        --modal-backButon-background-active:var( --secondary-Grayblue-200);

    /* textos */
        --texto-base-menu : 1.3rem;
        --primary-text-color: #333;
        --secondary-text-color: #ffffff;
        --terc-text-color: var(--secondary-red-400);
        --fourth-text-color: var(--secondary-red-400);
        --primary-background-color: #ffffff;

    /*--secondary-background-color: #30344F;*/
        --secondary-background-color: var(--secondary-Grayblue-500);
        --terc-background-color: rgba(48, 52, 79,0.7);
        --cuar-background-color: rgba(255,255,255, 1);
        --quin-background-color: rgba(48, 52, 79,1);
        --card-background-color1: #474984;

    /* --card-background-color2: #32317B; */
        --card-background-color2: var(--secondary-blue-500);
        --card-background-color3: var(--secondary-violet-500);
        --card-background-color4: var(--secondary-violet-800);
    
}