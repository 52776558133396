:root {
    --aside-nameBox-text-color: var(--secondary-Grayblue-500);
    --biografia-contact-text: var(--secondary-Grayblue-500);
    --biografia-textos-color-mobile: var(--primary-white);
}
#galeria {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.slideMain {
    border-radius: 8px;
    display: block;
    height: 100%;
    /* margin: 0 auto; */
    overflow: hidden;
    width: 100%;
}

.tira {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    position: relative;
    transition: all ease-in-out 0.7s;
    width: 100%;
}

.slide_imagen {
    background-position: center !important;
    background-size: cover;
    display: block;
    flex-grow: 1;
    height: 100%;
    left: 0;
    opacity: 0.8;
    transition: all linear 0.2s;
    min-width: 100%;
}
.biografia {
    background: rgba(255,255,255,0.08);
    border: solid 5px #FFF;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 68vh;
    /* overflow: hidden; */
    position: relative;
    width: 100%;
}
.biografia h1,.biografia h2,.biografia p {
    text-align: left;
}

.biografia_img {
    background-position: center;
    background-size: contain;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.5s ease-in;
    width: 100%;
}

.biografia_contact {
    align-self: center;
    background-color: #fff;
    bottom:-37px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(200, 200, 200, 0.4);
    box-sizing: border-box;
    color: var(--biografia-contact-text);
    display: flex;
    flex-direction: row;
    font-weight: bold;
    gap: 16px;
    justify-content: space-around;
    margin: 0 20%;
    padding: 16px 24px;
    position: absolute;
    width: 60%;
}

.biografia_contact li {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap:8px;
}

.biografia_contact li span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
}

a {
    color: inherit;
    text-decoration: none;
}

.biografia_caracter {
    position: absolute;
    bottom: 0;
    margin-left: 20vw;
    height: 80%;
    width: auto;
}


.biografia_textos {
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    backdrop-filter: blur(8px);
    /* border-left: 1px solid rgba(44, 47, 72, 1); */
    bottom:40px;
    /* box-shadow: 0 3px 10px rgba(200, 200, 200, 0.6); */
    color: var(--aside-nameBox-text-color);
    display: block;
    height: auto;
    margin-left: calc(50% - 50px);
    padding: 40px;
    position: absolute;
    width: 600px;
}

.biografia_nombre {
    display: flex;
    flex-direction: column;
    gap: 0;
    line-height: 52px;
}

.biografia_sobremi {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.biografia_sobremi a {
    border: 1px solid var(--terc-text-color);
    color: var(--secondary-text-color);
    cursor: pointer;
    padding: 8px 12px;
    transition: all 0.3s;
    width: fit-content;
}

.biografia_sobremi a:hover {
    background-color: var(--terc-text-color);
    color: var(--secondary-background-color);
}

.biografia_nombre h2 {
    /* color: rgb(191, 190, 216); */
    color: var(--terc-text-color);
}
@media (max-width: 700px){
    .biografia {
        border: none;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        height: 90vh;
        margin-bottom: 32px;
        width: 100%;
    }

    .slideMain {
        border-radius: 0;
    }
    
    .biografia_img {
        box-sizing: border-box;
        height: 50%;
        overflow: hidden;
        transition: all 0.5s ease-in;
        width: 100%;
    }
    .biografia_caracter {
        position: absolute;
        bottom: 0;
        margin-bottom: 40vh;
        margin-left: -10vw;
        height: 50vh;
        width: auto;
    }
    .biografia_contact{
        display: none;
        flex-direction: column;
    }
    .biografia_textos {
        background-color: var(--biografia-textos-color-mobile);
        border-radius:0;
        border-left: none;
        bottom:0;
        gap: 16px;
        height: 50%;
        justify-content:center;
        margin-left: 0;
        padding: 32px;
        position: relative;
        width: 100%;
    }
    .biografia_nombre {
        gap: 0;
        line-height: 52px;
        width: 100%;
    }
    .biografia_nombre h2{
        line-height: 32px;
    }
    .biografia_sobremi {
        width: 100%;
    }
}
@media only screen and (min-width: 701px) and (max-width: 768px){
    .slide_imagen {
        background-position: top right;
    }
}
@media (min-width: 769px){
    .slide_imagen {
        background-position: top center;
    }
}
@media (max-width: 768px){
    .slideMain {
        width: 100vw;
    }
    .slide_imagen {
        min-width: 100vw;
    }
}

@media only screen and (min-width: 701px) and (max-width: 1133px){
    .slide_imagen {
        background-position: top right;
    }
    .biografia_caracter {
        margin-left: 5vw;
    }
    .biografia_textos {
        margin-left: 45vw;
        width: 50%;
    }
    .biografia_contact {
        margin: 0 10%;
        width: 80%;
    }
}

@media only screen and (min-width: 1133px) and (max-width: 1366px){
    .biografia_contact {
        margin: 0 5%;
        width: 90%;
    }
}