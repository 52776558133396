label {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

input, textarea {
    background-color: var(--input-background-color);
    border: none;
    border-radius: 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1rem;
    padding: 12px;
}

input:focus, textarea:focus {
    outline: none;
}

input::placeholder, textarea::placeholder {
    color: var(--input-placeholder-color);
}

textarea { 
    min-height: 15vh;
    max-height: 300px;
}

button {
    background-color: var(--button-background-color);
    border: none;
    border-radius: 4px;
    color: var(--button-text-color);
    cursor: pointer;
    padding: 12px;
    transition: all ease 0.3ms;
}

button:hover {
    background-color: var(--button-backgroundHover-color);
    box-shadow: #00000063 0 0 10px;
}

button:disabled {
    box-shadow:none;
    color: var(--button-textDisabled-color);
    cursor:auto;
    background-color: var(--button-backgroundDisabled-color);
}