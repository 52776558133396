:root{
    --boton-descargaCv-border-color-active: var(--secondary-Grayblue-300);
    --boton-descargaCv-border-color-hover: var(--primary-white);
    --boton-descargaCv-bacground-color-hover: var(--secondary-Grayblue-500);

    --link-bacground-color-on: var(--secondary-Grayblue-500);

    --link-text-color-on-mobile: var(--secondary-Grayblue-500);
}
.header_menu {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.header_menu_options {
    display: flex;
    flex-direction: row;
    gap: 28px;
    padding: 0;
}

.header_menu_options li {
    cursor:pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    list-style: none;
    /* padding: 0; */
}
.header_menu_options li:hover {
    text-decoration: underline;
}
.Link {
    border-radius: 4px;
    text-decoration: none;
    color: inherit;
    padding: 0 8px;
}
.Link:hover ul {
    display: flex;
}
.on{
    background-color: var(--link-bacground-color-on);
    color: #fff;
}
.subMenu {
    border-radius: 4px;
    background-color:#fff;
    display: none;
    flex-direction: column;
    gap:10px;
    padding: 20px;
    top:100%;
}
.bot_dw_cv {
    border: solid 1px var(--boton-descargaCv-border-color-active);
    border-radius: 8px;
    font-size: 16px !important;
    padding: 8px 8px;
}
.bot_dw_cv:hover {
    background-color: var(--boton-descargaCv-border-color-hover);
    color: var(--boton-descargaCv-bacground-color-hover);
    text-decoration: none !important;
}

@media (max-width: 700px){
    .bot_dw_cv {
        display: none !important;
    }
    .Link {
        text-align: center;
        width: 25%;
    }
    .on{
        background-color: #fff;
        color: var(--link-text-color-on-mobile);
    }
}