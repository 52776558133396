.modal {
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(2px);
    display: flex;
    height: 100%;
    padding: 0;
    position: fixed;
    top:0;
    transition: all 0.2s linear;
    width: 100%;
    z-index: 10;
}

.modal_close {
    background-color: var(--secondary-Grayblue-500);
    border-radius: 50%;
    color: var(--primary-white);
    cursor: pointer;
    display: block;
    height: 24px;
    position: absolute;
    right: 24px;
    text-align: center;
    top: 16px;
    width: 24px;
    z-index: 2;
}
.contenedor {
    background-color: var(--modal-background-color);
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 60px auto;
    height: 70vh;
    /*max-width: 60vw;*/
    overflow: hidden;
    position: relative;
    width:70vw;
}
.contenedor_volver {
    display: none;
}
.img {
    align-self: end;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap:12px;
    height: 100%;
    overflow-y: auto;
    width:100%;
}

.img img {
    height: fit-content;
    width: 100%;
    object-fit:cover;
}
@media only screen and (min-width: 744px) and (max-width: 1180px){
    .contenedor {
        height: 95vh;
        margin: auto auto;
        width: 95vw;
    }
}
@media (max-width: 700px){
    /*modal*/
    .contenedor {
        border-radius: 0;
        flex-direction: column-reverse;
        height: 100vh;
        justify-content: flex-end;
        margin: 0;
        width: 100vw;
    }
    .contenedor_text {
        box-sizing: border-box;
        height: calc(40vh - 16px);
        max-width: 100%;
        min-height: auto;
        padding: 0 32px 16px 32px;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
    }
    .modal_close {
        display: none;
    }
    .img {
        flex-direction: column;
        flex-wrap: wrap;
        height: 50vh;
        max-height: 50vh;
    }
    .img img {
        height: 100%;
    }
    .contenedor_info {
        width: 100%;
    }
    .contenedor_text p {
        overflow: hidden;
        overflow-y: scroll;
        width: 100%;
    }
    .contenedor_volver {
        align-items: center;
        background-color: var(--modal-backButon-background-normal);
        bottom: 0;
        color : var(--modal-backButon-color);
        display: flex;
        height: 10vh;
        justify-content: center;
        margin: auto auto 0 auto;
        position: absolute;
        width: 100vw;
    }
    .contenedor_volver:active {
        background-color: var(--modal-backButon-background-active);
    }
    
}