:root {
    /*mensaje de error*/
    --errorMesage-background-color: var(--secondary-violet-300);
    --errorMesage-text-color: var(--primary-white);
    --okMesage-background-color: var(--primaryGreen-300);
    --okMesage-text-color: var(--primary-white);
}

.formularioContacto {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.formularioContacto label {
    color: var(--form-label-color);
    display: flex;
    font-weight: bold;
    flex-direction: column;
    gap: 8px;
}
.input_error_msg {
    color: var(--input-errorMesage-color);
    font-weight: normal;    
    margin-top: -4px;

}

input::placeholder {
    color: var(--input-plaholder-color)
}
.formularioContacto-mensaje {
    animation-name: slideout;
    animation-duration: 6s;
    animation-iteration-count: 1;
    border-radius: 4px;
    color: var(--errorMesage-text-color);
    overflow: hidden;
    padding: 24px;
}

._error {
    background: var(--errorMesage-background-color);
}

._ok {
    color : var(--okMesage-text-color);
    background: var(--okMesage-background-color);
}
@keyframes slideout {
    0% {
      height: auto;
    }
    90% {
        height: auto;
    }
    100% {
        height: 0;
    }
}
