:root{
    --footer-info-text-color: var(--secondary-Grayblue-500);
    --footer-borderTop-color: var(--secondary-Grayblue-300);
}
footer {
    box-sizing: border-box;
    bottom: 0px;
    border-top: solid 1px var(--footer-borderTop-color);
    color: #ffffff;
    padding: 40px 0;
    width: 60vw;
}
footer ul {
    display: flex;
    flex-direction: column;
    gap: 4px;
}
footer li,footer a {
    text-decoration: none;
    color: var(--footer-info-text-color);
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
}
footer li span {
    align-items: center;
    display: flex !important;
    height: 32px;
    justify-content: center;
    width: 32px;
}
@media (max-width: 700px){
    footer {
        padding: 32px 32px 21vh 32px;
        width: 100%;
    }
    
}
@media only screen and (min-width: 744px) and (max-width: 1133px){
    footer {
        box-sizing: border-box;
        bottom: 0px;
        border-top: solid 1px rgba(255,255,255,0.3);
        color: #ffffff;
        padding: 40px 0;
        width: 100%;
    }
}
@media only screen and (min-width: 1133px) and (max-width: 1366px){
    footer {
        width: 100%;
    }
}