:root{
    --about-title-text-color: var(--secondary-Grayblue-500);
    --about-experiencia-title-text-color: var(--secondary-Grayblue-500);
    --about-experiencia-bajada-text-color: var(--secondary-Grayblue-500);
}
.body_aboutme{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 140px;
    margin: 0 auto;
    width: 60vw;
}
.bloque_aboutme {
    border: solid 5px #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: 68vh;
    margin-bottom: 80px;
    overflow: hidden;
    width: 100%;
}
.bloque_aboutme_img{
    /* background-image: url('./assets/about/moto_tratada.jpg'); */
    background-blend-mode: hard-light;
    background-color: var(--secondary-background-color);
    background-position: top center;
    background-size: cover;
    height: 100%;
    transform: scaleX(-1);
    width: 100%;
}
.body_aboutme h2 {
    color: var(--about-title-text-color);
}
.section_quienSoy {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.section_quienSoy section {
    display: grid;
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
}
.section_quienSoy_column div {
    background-color: var(--cuar-background-color);
    box-sizing: border-box;
    border-radius: 0 8px 8px 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;
    padding: 32px;
}
.section_quienSoy_text{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.section_quienSoy  section  div:first-of-type div h2 {
    color: var(--secondary-text-color);
}
.section_quienSoy  section  div:first-of-type div{
    background-color: var(--quin-background-color) !important;
    background-blend-mode: multiply;
    border-radius: 8px 0 0 8px;
    color: var( --secondary-text-color);
}
.section_quienSoy_column {
    box-sizing: border-box;
    color: var( --icon-color-bloque-2);
    overflow: hidden;
    display: flex;
}

.section_aboutme_experiencia {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 56px;
    margin-bottom: 80px;
    padding: 0 0 68px 0;
    width: 100%;
}
.boton_cv {
    background: rgb(48,52,79);
    background: linear-gradient(90deg, rgba(48,52,79,1) 100%, rgba(48,52,79,1) 60%, rgba(255,255,255,1) 100%);
    border-radius: 8px;
    color: #fff;
    display: none;
    margin: 24px auto 0 auto;
    padding: 16px 24px;
    text-decoration: none;
    transition: all 0.3s ease;
}

.boton_cv:hover {
    background: rgb(48,52,79);
    background: linear-gradient(90deg, rgba(48,52,79,1) 10%, rgba(107, 37, 91,1) 100%);
    box-shadow: 1px 1px 5px rgba(30,30,30,5);
}

.section_aboutme_block {
    box-sizing: border-box;
    flex-grow: 1;
    width: 50%;
}
.section_aboutme_block h2 {
    color: var(--about-experiencia-title-text-color);
    margin-bottom: 8px;
    padding: 0;
}
.experiencia_bajada{
    color: var(--about-experiencia-bajada-text-color);
    padding: 0;
}
.lineatiempo {
    align-items: flex-end;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    padding: 24px 0 0 0;
}
@media only screen and (min-width: 1133px) and (max-width: 1366px){
    .body_aboutme{
        width: 100%;
    }
}

@media only screen and (min-width: 744px) and (max-width: 1133px){
    .body_aboutme {
        width: 100%;
    }
    .section_aboutme_descripcion {
        width: 100%;
    }
    .section_aboutme {
        left: -8px;
        width: 45vw;
    }
    .bloque_aboutme_img {
        background-size: cover;
    }
}
@media (max-width: 700px){
    .bloque_aboutme {
        border: none;
        border-radius: 0;
        height: 50vh;
        flex-direction: column;
        margin-bottom: 40px;
    }
    .bloque_aboutme_img {
        background-size: cover;
        height: 50vh;
        width: 100vw;
    }
    .section_aboutme {
        /* background-color: rgba(48,52,79,1); */
        background-color: rgba(255,255,255,1);
        border-radius: 0;
        position: relative;
        width: 100vw;
    }
    .section_aboutme_descripcion {
        width: 100%;
    }
    .body_aboutme {
        width: 100vw;
    }
    .boton_cv {
        width: fit-content;
        display: block;
    }

    /*experieincia*/
    .section_aboutme_experiencia {
        flex-direction: column-reverse;
    }
    .section_aboutme_block {
        padding: 0 32px;
        width: 100%;
    }
    .section_aboutme_experiencia {
        gap: 64px;
    }

    /*seccion quien soy*/
    .section_quienSoy {
        display: flex;
        gap: 16px;
        flex-direction: column;
    }
    .section_quienSoy section {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .titulo_quienSoy {
        padding: 0 32px;
    }
    .section_quienSoy > section > div:first-of-type div{
        background-color: var(--terc-background-color) !important;
        color: var( --secondary-text-color);
    }
    .section_quienSoy_column {
        border-radius: 1px;
        box-sizing: border-box;
        /* color: var(--secondary-text-color); */
        color: var( --icon-color-bloque-2);
        overflow: hidden;
        display: flex;
    }
}
