@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

h1, h2 {
    font-family: "Anton", sans-serif;
    font-weight:normal;
    margin: 0;
    padding: 0;
    text-indent: -2;
}

h3, h4, h5, h6 {
    font-family: 'Lato';
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-indent: -2;
}

li {
    font-size: 1.3rem;
}

h1 {
    font-size: 3rem;
    letter-spacing: normal;
}

h2 {
    font-size: 2rem;
    letter-spacing: normal;
}

h3 {
    font-size: 1.5rem;
    letter-spacing: normal;
}

h4 {
    font-size: 1.3rem;
    letter-spacing: normal;
}

h5 {
    font-size: 1.1rem;
    letter-spacing: normal;
}
h6 {
    font-size: 0.8rem;
    letter-spacing: normal;
}

p {
    font-family:'Lato';
    line-height: 22px;
    margin: 0;
    padding: 0;
    font-size: 0.96rem;
    text-wrap: pretty;
    text-indent: -1;
}

.contenedor_text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px 0 32px 32px;
    min-height: fit-content;
    max-width: 300px;
    text-align: left;
    width: 30vw;
}

.contenedor_title {
    font-size: 2em;
    text-align: right;
}

.contenedor_descripcion {
    font-size: 1em;
}

@media (max-width: 700px){
    .main #title {
        padding: 0 32px;
    }
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 0.9rem;
    }
    h6 {
        font-size: 0.7rem;
        letter-spacing: normal;
    }
}