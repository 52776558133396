header {
    align-items: center;
    box-sizing: border-box;
    color: rgba(44,47,72,1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 20vw;
    position: sticky;
    top: 0;
    transition: all 0.1s linear;
    width: calc(100% + 80px);
    z-index: 3;
}
.bg_w {
    /* background-color: rgba(44,47,72,0.8); */
    background-color: rgba(234, 235, 237,0.8);
    backdrop-filter: blur(2px);
    padding: 0 20vw;
}
.txt_col {
    color: #fff !important;
}

.header_brand {
    background-position: center;
    background-size: contain;
    border-radius: 0 0 4px 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: all 0.1s linear;
}

.logo img {
    width: 100%;
}

.logo_name {
    text-decoration: none;
}

.logoL {
    height: 60px;
    width: 60px;
}

.logoS {
    height: 3vw;
    width: 3vw;
}

.header_menu_options li {
    font-size: 18px;
    font-weight: 500;
}

@media only screen and (min-width: 744px) and (max-width: 1440px){
    header {
        font-size: 1rem;
        padding: 0 40px;
        width: calc(100% + 80px);
    }
    .logo img {
        width: 100%;
    }
    .bg_w {
        padding: 4px 40px;
    }
}
@media (max-width: 700px){
    header {
        background-color: #ffffff;
        color: #333;
        height: 10vh;
        margin: 0;
        padding: 0 16px;
        position: fixed;
        width: 100%;
    }
    .logoL, .logoS {
        background-color: #fff;
        border-radius: 50%;
        height: 15vw;
        top: -30px;
        position: absolute;
        width: 15vw;
        margin: 0 calc(74% / 2);
        padding: 4px;
    }
    .header_menu_options {
        justify-content: space-between;
        gap: 16px;
        width: 100%;
    }
    .header_menu_options li {
        font-size: 16px;
        font-weight: bold;
    }
    .header_menu {
        padding-top: 8px;
        width: 100%;
        z-index: 2;
    }
    .bg_w {
        background-color: rgba(255,255,255,1);
        backdrop-filter:none;
        border-radius: 0;
        padding: 0 16px;
    }
    .txt_col {
        color: #333 !important;
    }

    
}