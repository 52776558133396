:root {
    --icon-color-bloque-1: var(--primary-white);
    --icon-color-bloque-2: var(--secondary-Grayblue-500);
}
.section_quehago {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-weight: normal;
    gap: 16px;
    margin-bottom: 80px;
    width: 100%;
}
.section_quehago section {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}

.titulo_queHago {
    color: var(--about-title-text-color);
    padding:0;
}

.section_quehago_head {
    width: max-content;
}
.section_quehago_text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    flex-grow: 2;
}
.section_quehago_column a {
    color: var(--secondary-Grayblue-200);
    cursor: pointer;
    text-decoration: none;
}
.b {
    flex-direction: column;
    gap:24px;
}
.section_quehago h2{
    width: max-content;
}
.section_quehago_column {
    border-radius: 8px;
    box-sizing: border-box;
    /* color: var(--secondary-text-color); */
    color: var( --icon-color-bloque-2);
    overflow: hidden;
    display: flex;
}
.section_quehago_column div {
    background-color: var(--cuar-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;
    padding: 32px;
}
.section_quehago > section > div:first-of-type div{
    background-color: var(--terc-background-color) !important;
    color: var( --secondary-text-color);
}
.sub_desc {
    color: var(--terc-text-color);
    margin-bottom: 4px;
}
.section_quehago_skills {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;
    padding-top:16px;
}
.section_quehago_skills h5 {
    width: 100%;
}
.section_quehago_skills img {
    height: 32px;
    width: 32px;
}
@media only screen and (min-width: 744px) and (max-width: 1133px){
    .section_quehago section {
        height: max-content;
    }
    .section_quehago {
        height: max-content;
    }
    .titulo_queHago {
        padding: 0 32px;
    }
}
@media (max-width: 700px){
    .titulo_queHago {
        padding:0 32px;
    }
    .section_quehago_text {
        width: 100%;
    }
    .section_quehago section {
        height: auto;
        gap:8px;        
    }
    .section_quehago {
        height: auto;
    }
    .section_quehago_column {
        border-radius: 0px;
    }
}